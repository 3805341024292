import React from "react";
import { PageLayout, Background, ContactForm } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class EventFormPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness } = data;

    return (
      <WebsiteLayout>
        <PageLayout
          hero={
            <Background
              columns={1}
              stackable={true}
              className={"Event Form about-hero"}
            />
          }
          subfooter={false}
          className={"about-pag event-page"}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12} textAlign={"center"}>
              <Grid
                stackable={true}
                className={"component-section-container"}
                verticalAlign={"middle"}
                textAlign={"center"}
              >
                <Grid.Column textAlign={"center"} width={16}>
                  <h2>
                    Interested in hosting your event at Noonan's Event Center?
                  </h2>
                  <p>
                    Please fill out the form below with your contact details and
                    information about your event. Our Event Coordinator Anna
                    will be in touch!
                  </p>
                </Grid.Column>
                <Grid.Column width={16} textAlign={"left"} centered={true}>
                  <ContactForm
                    constructMessage={({
                      name,
                      email,
                      date,
                      phone,
                      message,
                      type,
                      guests,
                    }) =>
                      `Someone has reached out to you via your website event form: Name: ${name}, Email: ${email}, Event Date: ${date}, Phone: ${phone}, Event Type: ${type}, Number Of Guests: ${guests}, Message: ${message}`
                    }
                    fields={[
                      {
                        label: "name",
                        placeholder: "Name",
                        type: "input",
                        required: true,
                      },
                      {
                        label: "email",
                        placeholder: "Email",
                        type: "input",
                        inputType: "email",
                        required: true,
                      },
                      {
                        label: "phone",
                        placeholder: "Phone Number",
                        type: "input",
                        required: true,
                      },
                      {
                        label: "date",
                        labelToDisplay: "Event Date",
                        placeholder: "Event Date",
                        type: "input",
                        inputType: "date",
                        required: true,
                      },
                      {
                        label: "type",
                        labelToDisplay: "Event Type",
                        placeholder: "Event Type",
                        type: "input",
                        required: true,
                      },
                      {
                        label: "guests",
                        labelToDisplay: "Number Of Guests",
                        placeholder: "Number Of Guests",
                        type: "input",
                        required: true,
                      },
                      {
                        label: "message",
                        labelToDisplay: "Optional Message",
                        placeholder: "Optional Message",
                        type: "textarea",
                        required: false,
                      },
                    ]}
                    buttonText={"Send"}
                    centeredHeaders={true}
                    className={""}
                    fluidButton={true}
                    header={""}
                    inline={false}
                    showLabels={true}
                    subheader={""}
                    termsCopy={""}
                    withContainer={true}
                    locationId={fishermanBusiness.primaryLocation._id}
                    businessId={fishermanBusiness._id}
                    businessType={fishermanBusiness.type}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      primaryLocation {
        _id
      }
      _id
      type
    }
  }
`;
